import React from "react"
import { Link as GatsbyLink } from "gatsby"

const Link = ({ activeClassName, partiallyActive, to, children, ...other }) => {
  // If a URL starts with something that looks vaguely like a protocol (e.g. http://, https://,
  // etc.), then it's not internal.
  const external = /^[A-z]+:\/\//.test(to)

  // Use Gatsby Link for internal links, and <a> for others
  if (!external) {
    if (to.substring(to.length-1) !== "/") {
      // If the destination of the link doesn't end with a slash, append one to the end, otherwise
      // we'll end up with 301s in certain hosting environments.
      to = to + "/"
    }

    return (
      <GatsbyLink
        to={to}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
        {...other}
      >
        {children}
      </GatsbyLink>
    )
  }

  return (
    <a href={to} rel="noreferrer" {...other}>
      {children}
    </a>
  )
}

export default Link
