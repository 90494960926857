import React from "react"
import Helmet from "react-helmet"

import Navigation from "./Navigation"
import Footer from "./Footer"

import "../scss/index.scss"

const Layout = ({ children }) => {
  return (
    <>
      <Helmet>
        <html lang="en" />
        <title>Elliot Wright</title>
      </Helmet>
      <Navigation />
      {children}
      <Footer />
    </>
  )
}

export default Layout
