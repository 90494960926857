import React from "react"
import { useSiteMetadata } from "../hooks/siteMetadata"

import styles from "../scss/components/Footer.module.scss"

const Footer = () => {
  const { title } = useSiteMetadata()

  return (
    <footer className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.copyright}>
          &copy; {new Date().getFullYear()}, <em>{title}</em>
        </div>
      </div>
    </footer>
  )
}

export default Footer
