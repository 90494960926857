import React from "react"
import { useSiteMetadata } from "../hooks/siteMetadata"

import Link from "./Link"
import styles from "../scss/components/Navigation.module.scss"

const Navigation = () => {
  const { title } = useSiteMetadata()

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.logo}>
          <Link to={`/`}>{title}</Link>
        </div>

        <div className={styles.links}>
          <ul role="menu">
            <li role="menuitem">
              <Link to="https://github.com/seeruk" target="_blank">GitHub</Link>
            </li>
            <li role="menuitem">
              {/* This one actually has to be an anchor, because of the appended `/` */}
              <a href="/rss.xml">RSS</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Navigation
